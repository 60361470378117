<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px">
      <el-form-item label="类型" prop="type">
        <el-radio-group v-model="dataForm.type">
          <el-radio v-for="(type, index) in dataForm.typeList" :label="index" :key="index">{{ type }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="dataForm.typeList[dataForm.type] + '名称'" prop="name">
        <el-input v-model="dataForm.name" :placeholder="dataForm.typeList[dataForm.type] + '名称'"></el-input>
      </el-form-item>
      <el-form-item label="上级菜单" prop="parentName">
        <el-popover
          ref="menuListPopover"
          placement="bottom-start"
          v-model="popoverVisible"
          trigger="click">
          <el-tree
            :data="menuList"
            :props="menuListTreeProps"
            node-key="menuId"
            ref="menuListTree"
            @current-change="menuListTreeCurrentChangeHandle"
            :highlight-current="true"
            :expand-on-click-node="false">
          </el-tree>
        </el-popover>
        <el-input v-model="dataForm.parentName" v-popover:menuListPopover :readonly="true" placeholder="点击选择上级菜单" class="menu-list__input"></el-input>
      </el-form-item>
        <el-form-item label="分组" prop="menuGroup" v-if="dataForm.type === 0">
            <el-select v-model="dataForm.menuGroup" clearable placeholder="请选择">
                <el-option
                    v-for="item in menuGroup_options"
                    :key="item.itemKey"
                    :label="item.itemValue"
                    :value="item.itemKey">
                </el-option>
            </el-select>
        </el-form-item>
      <el-form-item v-if="dataForm.type === 1" label="菜单路由" prop="url">
        <el-input v-model="dataForm.url" placeholder="菜单路由"></el-input>
      </el-form-item>
      <el-form-item v-if="dataForm.type !== 0" label="授权标识" prop="perms">
        <el-input v-model="dataForm.perms" placeholder="多个用逗号分隔, 如: user:list,user:create" type="textarea"></el-input>
      </el-form-item>
      <el-form-item v-if="dataForm.type !== 2" label="排序号" prop="orderNum">
        <el-input-number v-model="dataForm.orderNum" controls-position="right" :min="0" label="排序号"></el-input-number>
      </el-form-item>
      <el-form-item  label="是否显示" prop="isShow">
        <el-switch v-model="dataForm.isShow"></el-switch>
      </el-form-item>
      <el-form-item v-if="dataForm.type !== 2" label="菜单图标" prop="icon">
        <el-row>
          <el-col :span="22">
            <el-popover
              ref="iconListPopover"
              placement="bottom-start"
              trigger="click"
              popper-class="mod-menu__icon-popover">
              <div class="mod-menu__icon-inner">
                <div class="mod-menu__icon-list">
                  <el-button
                    v-for="(item, index) in iconList"
                    :key="index"
                    @click="iconActiveHandle(item)"
                    :class="{ 'is-active': item === dataForm.icon }">
                    <icon-svg :name="item"></icon-svg>
                  </el-button>
                </div>
              </div>
            </el-popover>
            <el-input v-model="dataForm.icon" v-popover:iconListPopover placeholder="菜单图标名称" class="icon-list__input"></el-input>
          </el-col>
          <el-col :span="2" class="icon-list__tips">
            <el-tooltip placement="top" effect="light">
              <div slot="content">全站推荐使用SVG Sprite, 详细请参考:<a href="//github.com/daxiongYang/renren-fast-vue/blob/master/src/icons/index.js" target="_blank">icons/index.js</a>描述</div>
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {treeDataTranslate} from '@/utils';
import Icon from '@/icons';
export default {
    props:['menuGroup_options'],
    data() {
        const validateUrl = (rule, value, callback) => {
            if (this.dataForm.type === 1 && !/\S/.test(value)) {
                callback(new Error('菜单URL不能为空'));
            } else {
                callback();
            }
        };
        return {
            visible: false,
            popoverVisible: false,
            dataForm: {
                id: 0,
                type: 1,
                typeList: ['目录', '菜单', '按钮'],
                name: '',
                parentId: 0,
                parentName: '',
                menuGroup: '',
                url: '',
                perms: '',
                orderNum: 0,
                icon: '',
                isShow: true,
                iconList: []
            },
            dataRule: {
                name: [
                    {required: true, message: '菜单名称不能为空', trigger: 'blur'}
                ],
                parentName: [
                    {required: true, message: '上级菜单不能为空', trigger: 'change'}
                ],
                url: [
                    {validator: validateUrl, trigger: 'blur'}
                ]
            },
            menuList: [],
            menuListTreeProps: {
                label: 'name',
                children: 'children'
            }
        };
    },
    created() {
        this.iconList = Icon.getNameList();
    },
    methods: {
        init(id) {
            this.dataForm.id = id || 0;
            this.$client.getSelectMenuList().then((data) => {
                this.menuList = treeDataTranslate(data.menuList, 'menuId');
            }).then(() => {
                this.visible = true;
                this.$nextTick(() => {
                    this.$refs.dataForm.resetFields();
                });
            }).then(() => {
                if (!this.dataForm.id) {
                    // 新增
                    this.menuListTreeSetCurrentNode();
                } else {
                    // 修改
                    this.$client.getMenuById(this.dataForm.id).then((data) => {
                        this.dataForm.id = data.menu.menuId;
                        this.dataForm.type = data.menu.type;
                        this.dataForm.name = data.menu.name;
                        this.dataForm.parentId = data.menu.parentId;
                        this.dataForm.url = data.menu.url;
                        this.dataForm.menuGroup = data.menu.menuGroup;
                        this.dataForm.perms = data.menu.perms;
                        this.dataForm.orderNum = data.menu.orderNum;
                        this.dataForm.icon = data.menu.icon;
                        this.dataForm.isShow = data.menu.isShow ? true : false;
                        this.menuListTreeSetCurrentNode();
                    });
                }
            });
        },
        // 菜单树选中
        menuListTreeCurrentChangeHandle(data) {
            this.dataForm.parentId = data.menuId;
            this.dataForm.parentName = data.name;
            this.popoverVisible = false;
        },
        // 菜单树设置当前选中节点
        menuListTreeSetCurrentNode() {
            this.$refs.menuListTree.setCurrentKey(this.dataForm.parentId);
            this.dataForm.parentName = (this.$refs.menuListTree.getCurrentNode() || {}).name;
        },
        // 图标选中
        iconActiveHandle(iconName) {
            this.dataForm.icon = iconName;
        },
        // 表单提交
        dataFormSubmit() {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    const submit = this.dataForm.id ? this.$client.updateMenu : this.$client.saveMenu;
                    submit({
                        menuId: this.dataForm.id || undefined,
                        type: this.dataForm.type,
                        name: this.dataForm.name,
                        parentId: this.dataForm.parentId,
                        url: this.dataForm.url,
                        menuGroup: this.dataForm.menuGroup,
                        perms: this.dataForm.perms,
                        orderNum: this.dataForm.orderNum,
                        isShow: this.dataForm.isShow ? 1 : 0,
                        icon: this.dataForm.icon
                    }).then((data) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500,
                                onClose: () => {
                                }
                            });

                            this.visible = false;
                            this.$emit('refreshDataList');
                        } else {
                            this.$message.error(data.msg);
                        }
                    });
                }
            });
        }
    }
};
</script>

