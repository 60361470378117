<template>
    <div class="mod-menu">
        <el-form :inline="true" :model="dataForm">
            <el-form-item>
                <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
                <el-button type="primary" @click="addOrUpdateHandle(1)">修改</el-button>
                <el-button type="primary" @click="deleteHandle()">删除</el-button>
            </el-form-item>
        </el-form>

        <el-table
            :data="dataList"
            row-key="menuId"
            border
            @selection-change="selectionChangeHandle"
            style="width: 100%; ">
            <el-table-column
                type="selection"
                header-align="center"
                align="center"
                width="50">
            </el-table-column>
            <el-table-column
                prop="name"
                header-align="center"
                min-width="150"
                label="名称">
            </el-table-column>
            <el-table-column
                prop="parentName"
                header-align="center"
                align="center"
                width="120"
                label="上级菜单">
            </el-table-column>
            <el-table-column
                header-align="center"
                align="center"
                label="图标">
                <template slot-scope="scope">
                    <icon-svg :name="scope.row.icon || ''"></icon-svg>
                </template>
            </el-table-column>
            <el-table-column
                prop="type"
                header-align="center"
                align="center"
                label="类型">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.type === 0" size="small">目录</el-tag>
                    <el-tag v-else-if="scope.row.type === 1" size="small" type="success">菜单</el-tag>
                    <el-tag v-else-if="scope.row.type === 2" size="small" type="info">按钮</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                prop="orderNum"
                header-align="center"
                align="center"
                label="排序号">
            </el-table-column>
            <el-table-column
                prop="url"
                header-align="center"
                align="center"
                width="150"
                :show-overflow-tooltip="true"
                label="菜单URL">
            </el-table-column>
            <el-table-column
                prop="perms"
                header-align="center"
                align="center"
                width="150"
                :show-overflow-tooltip="true"
                label="授权标识">
            </el-table-column>

            <el-table-column
                prop="isShow"
                header-align="center"
                align="center"
                width="80"
                label="是否显示">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.isShow==1" type="success">
                        显示
                    </el-tag>
                    <el-tag v-else type="danger">
                        不显示
                    </el-tag>
                </template>
            </el-table-column>
            <!--<el-table-column
                fixed="right"
                header-align="center"
                align="center"
                width="150"
                label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" size="small" @click="addOrUpdateHandle(scope.row.menuId)">修改</el-button>
                    <el-button type="primary" size="small" @click="deleteHandle(scope.row.menuId)">删除</el-button>
                </template>
            </el-table-column>-->
        </el-table>
        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" :menuGroup_options='menuGroup_options' @refreshDataList="getDataList"></add-or-update>
    </div>
</template>

<script>
import {treeDataTranslate} from '@/utils';
import AddOrUpdate from './menu-add-or-update';

export default {
    data() {
        return {
            dataForm: {},
            dataList: [],
            menuGroup_options: [],
            dataListLoading: false,
            addOrUpdateVisible: false,
            dataListSelections: []
        };
    },
    components: {
        AddOrUpdate
    },
    created() {
        this.$client.getDicData('menu_group').then((res) => {
            this.menuGroup_options = res.data;
        });
        this.getDataList();
    },
    methods: {
        // 获取数据列表
        getDataList() {
            this.dataListLoading = true;
            this.$client.getMenuList().then(({data}) => {
                this.dataList = treeDataTranslate(data, 'menuId');
                this.dataListLoading = false;
            });
        },
        // 新增 / 修改
        addOrUpdateHandle(status) {
            let id = '';
            if (status) {
                //编辑
                if (!this.preValidate(1)) {
                    return;
                }
                id = this.dataListSelections[0].menuId;
            }
            this.addOrUpdateVisible = true;
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(id);
            });
        },
        // 删除
        deleteHandle() {
            if (!this.preValidate()) {
                return;
            }
            const ids = this.dataListSelections.map(item => {
                return item.menuId;
            });
            this.$confirm(`确定删除选中数据?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$client.delMenuBatch(ids).then((data) => {
                    if (data && data.code === 0) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                            onClose: () => {
                                this.getDataList();
                            }
                        });
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            }).catch(() => {
            });
        },
        selectionChangeHandle(val) {
            this.dataListSelections = val;
        },
        preValidate(isSingle) {
            if (this.dataListSelections == null || this.dataListSelections.length < 1) {
                this.$message({
                    type: 'warning',
                    message: '请先选择待操作数据'
                });
                return false;
            }
            //是否只能选一条数据的校验
            if (isSingle) {
                if (this.dataListSelections.length > 1) {
                    this.$message({
                        type: 'warning',
                        message: '只允许对一条进行操作'
                    });
                    return false;
                }
            }
            return true;
        },
    }
};
</script>
